import { useEffect } from 'react';

import {
  BrowserRouter as Router,
  Switch,
  Route as RRDRoute,
} from 'react-router-dom';
import { ThemeProvider } from '@emotion/react';
import { Global } from '@emotion/react';

import { AppStoreProvider } from './context/appStore';
import { AuthProvider } from './context/auth';
import { SSOProvider } from './context/SSO';

import * as Pages from './pages';
import { Route } from './components';
import { navRoutes, roles } from './constants';
import theme, { globalStyle } from './theme';
// import UploadTest from './pages/UploadTest';
import './App.css';
import 'antd/dist/reset.css';

import Hotjar from '@hotjar/browser';

// keeping just to make sure new hotjar package works - otherwise will revert
// import { hotjar } from 'react-hotjar';

// import hotJarConfig from './hotJarConfig';

function App() {
  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      // hotjar.initialize(process.env.REACT_APP_HOT_JAR_ID);

      // if (hotjar.initialized()) {
      //   console.log('Hotjar initialized');
      // }
      const siteId = process.env.REACT_APP_HOT_JAR_ID;
      const hotjarVersion = 6;
      Hotjar.init(siteId, hotjarVersion);
    }
  }, []);

  // get Hotjar to track pages
  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      Hotjar.stateChange(window.location.pathname);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname]);

  return (
    <div className="app">
      <Global styles={globalStyle} />
      <ThemeProvider theme={theme}>
        <Router>
          <AuthProvider>
            <AppStoreProvider>
              <Switch>
                <Route
                  exact
                  path={navRoutes.GENERAL.SIGNOUT}
                  Component={Pages.SignOut}
                  layout="fullScreen"
                />

                <RRDRoute exact path={'*'}>
                  <SSOProvider>
                    <Switch>
                      <Route
                        exact
                        path={navRoutes.VOLUNTEER.SIGNUP}
                        Component={Pages.SSOSignupVolunteerNoSSO}
                        layout="fullScreen"
                      />
                      <Route
                        exact
                        path={navRoutes.DIGITAL_CHAMPION.SIGNUP}
                        Component={Pages.SSOSignupDigitalChampionNoSSO}
                        layout="fullScreen"
                      />

                      <Route
                        exact
                        path={[
                          navRoutes.DIGITAL_CHAMPION.TRAINING_PAGES,
                          navRoutes.DIGITAL_CHAMPION.TRAINING_PAGE,
                        ]}
                        Component={Pages.DigitalChampionTraining}
                        isPrivate
                        allowedRoles={[roles.DIGITAL_CHAMPION]}
                        layout="fullScreen"
                        hideBack
                      />
                      <Route
                        exact
                        path={navRoutes.DIGITAL_CHAMPION.DASHBOARD}
                        Component={Pages.DigitalChampionDashboard}
                        isPrivate
                        allowedRoles={[roles.DIGITAL_CHAMPION]}
                        layout="fullScreen"
                        hideBack
                      />
                      <Route
                        exact
                        path={navRoutes.DIGITAL_CHAMPION.SETTINGS}
                        Component={Pages.ChampionAccount}
                        isPrivate
                        allowedRoles={[roles.DIGITAL_CHAMPION]}
                        layout="fullScreen"
                        hideBack
                      />
                      <Route
                        exact
                        path={navRoutes.DIGITAL_CHAMPION.MY_ASSIGNED_REQUESTS}
                        Component={Pages.RequestsForHelp}
                        isPrivate
                        allowedRoles={[roles.DIGITAL_CHAMPION]}
                        layout="fullScreen"
                      />
                      <Route
                        exact
                        path={
                          navRoutes.DIGITAL_CHAMPION.SUGGEST_ACTIVITY_SUCCESS
                        }
                        Component={Pages.SuggestSuccess}
                        isPrivate
                        allowedRoles={[roles.DIGITAL_CHAMPION]}
                        layout="fullScreen"
                      />
                      <Route
                        exact
                        path={navRoutes.DIGITAL_CHAMPION.SUGGEST_ACTIVITY}
                        Component={Pages.SuggestActivity}
                        isPrivate
                        allowedRoles={[roles.DIGITAL_CHAMPION]}
                        layout="fullScreen"
                      />
                      <Route
                        exact
                        path={[
                          navRoutes.DIGITAL_CHAMPION.ALL_QUESTIONS,
                          navRoutes.HQ.ALL_QUESTIONS,
                        ]}
                        Component={Pages.AllQuestions}
                        isPrivate
                        allowedRoles={[roles.DIGITAL_CHAMPION, roles.HQ]}
                        layout="fullScreen"
                      />
                      <Route
                        exact
                        path={navRoutes.HQ.QUESTIONS}
                        Component={Pages.HqQuestions}
                        isPrivate
                        allowedRoles={[roles.HQ]}
                        layout="fullScreen"
                      />
                      <Route
                        exact
                        path={[
                          navRoutes.DIGITAL_CHAMPION.SOLVED_QUESTIONS,
                          navRoutes.HQ.SOLVED_QUESTIONS,
                        ]}
                        Component={Pages.SolvedQuestions}
                        isPrivate
                        allowedRoles={[roles.DIGITAL_CHAMPION, roles.HQ]}
                        layout="fullScreen"
                      />
                      <Route
                        exact
                        path={navRoutes.DIGITAL_CHAMPION.BANK_OF_QUESTIONS}
                        Component={Pages.BankOfQuestions}
                        isPrivate
                        allowedRoles={[roles.DIGITAL_CHAMPION]}
                        layout="fullScreen"
                      />
                      <Route
                        exact
                        path={[
                          navRoutes.DIGITAL_CHAMPION.REQUEST_DETAILS,
                          navRoutes.HQ.REQUEST_DETAILS,
                        ]}
                        Component={Pages.ChampionViewSingleRequest}
                        isPrivate
                        allowedRoles={[roles.DIGITAL_CHAMPION, roles.HQ]}
                        layout="fullScreen"
                      />
                      <Route
                        exact
                        path={navRoutes.DIGITAL_CHAMPION.UNASSIGN}
                        Component={Pages.UnassignRequest}
                        isPrivate
                        allowedRoles={[roles.DIGITAL_CHAMPION]}
                        layout="fullScreen"
                      />
                      <Route
                        exact
                        path={
                          navRoutes.DIGITAL_CHAMPION
                            .GUIDE_TO_BEING_A_DIGITAL_CHAMPION_SUB_PAGE
                        }
                        Component={Pages.GuideToBeingADigitalChampion}
                        isPrivate
                        allowedRoles={[roles.DIGITAL_CHAMPION]}
                        layout="fullScreen"
                      />
                      <Route
                        exact
                        path={[
                          navRoutes.DIGITAL_CHAMPION.QUESTION_DETAILS,
                          navRoutes.HQ.QUESTION_DETAILS,
                          navRoutes.GENERAL.QUESTION_DETAILS,
                        ]}
                        Component={Pages.IndividualQuestion}
                        layout="fullScreen"
                      />
                      <Route
                        exact
                        path={[
                          navRoutes.DIGITAL_CHAMPION.ANSWER_QUESTION,
                          navRoutes.HQ.ANSWER_QUESTION,
                        ]}
                        Component={Pages.AnswerQuestion}
                        isPrivate
                        allowedRoles={[roles.DIGITAL_CHAMPION, roles.HQ]}
                        layout="fullScreen"
                      />

                      <Route
                        exact
                        path={[
                          navRoutes.VOLUNTEER.THANKS_FOR_ANSWER_QUESTION,
                          navRoutes.DIGITAL_CHAMPION.THANKS_FOR_ANSWER_QUESTION,
                          navRoutes.HQ.THANKS_FOR_ANSWER_QUESTION,
                        ]}
                        Component={Pages.ThanksForAnswerQuestion}
                        isPrivate
                        allowedRoles={[
                          roles.DIGITAL_CHAMPION,
                          roles.HQ,
                          roles.VOLUNTEER,
                        ]}
                        layout="fullScreen"
                        hideBack
                      />
                      <Route
                        exact
                        path={[
                          navRoutes.VOLUNTEER.SUCCESS_SOLVED_QUESTION,
                          navRoutes.GENERAL.THANKS_FOR_ASK_QUESTION,
                          navRoutes.VOLUNTEER.THANKS_FOR_SEND_REPLY,
                        ]}
                        Component={Pages.ThanksForAskQuestion}
                        layout="fullScreen"
                      />
                      <Route
                        exact
                        path={navRoutes.GENERAL.QUESTION_VIA_SKILL_OR_ACTIVITY}
                        Component={Pages.QuestionViaSkillOrActivity}
                        layout="fullScreen"
                        hideBack
                      />
                      <Route
                        exact
                        path={
                          navRoutes.DIGITAL_CHAMPION
                            .GUIDE_TO_BEING_A_DIGITAL_CHAMPION_MAIN
                        }
                        Component={Pages.GuideToBeingADigitalChampion}
                        isPrivate
                        allowedRoles={[roles.DIGITAL_CHAMPION]}
                        layout="fullScreen"
                      />
                      <Route
                        exact
                        path={navRoutes.DIGITAL_CHAMPION.FIND_SKILLS}
                        Component={Pages.FindSkills}
                        isPrivate
                        allowedRoles={[roles.DIGITAL_CHAMPION]}
                        layout="fullScreen"
                      />
                      {/* HQ routes */}
                      <Route
                        exact
                        path={navRoutes.HQ.VOLUNTEERS}
                        Component={Pages.HqVolunteersStats}
                        isPrivate
                        allowedRoles={[roles.HQ]}
                        layout="fullScreen"
                      />
                      <Route
                        exact
                        path={navRoutes.HQ.MANAGE_SKILLS}
                        Component={Pages.SkillAreas}
                        isPrivate
                        allowedRoles={[roles.HQ]}
                        layout="fullScreen"
                      />

                      <Route
                        exact
                        path={navRoutes.HQ.DASHBOARD}
                        Component={Pages.HqDashboard}
                        isPrivate
                        allowedRoles={[roles.HQ]}
                        layout="fullScreen"
                        hideBack
                      />
                      <Route
                        exact
                        path={navRoutes.HQ.SKILLS_PROGRESS}
                        Component={Pages.HqSkillsProgress}
                        isPrivate
                        allowedRoles={[roles.HQ]}
                        layout="fullScreen"
                      />

                      <Route
                        exact
                        path={navRoutes.HQ.EDIT_INDIVIDUAL_SKILL}
                        Component={Pages.HqEditIndividualSkill}
                        isPrivate
                        allowedRoles={[roles.HQ]}
                        layout="fullScreen"
                      />
                      <Route
                        exact
                        path={navRoutes.HQ.CREATE_SKILL}
                        Component={Pages.HqEditIndividualSkill}
                        isPrivate
                        allowedRoles={[roles.HQ]}
                        layout="fullScreen"
                      />
                      <Route
                        exact
                        path={navRoutes.HQ.INDIVIDUAL_SKILL}
                        Component={Pages.HqIndividualSkill}
                        isPrivate
                        allowedRoles={[roles.HQ]}
                        layout="fullScreen"
                      />
                      <Route
                        exact
                        path={navRoutes.HQ.EDIT_INDIVIDUAL_SKILL_AREA}
                        Component={Pages.HqEditIndividualSkillArea}
                        isPrivate
                        allowedRoles={[roles.HQ]}
                        layout="fullScreen"
                      />
                      <Route
                        exact
                        path={navRoutes.HQ.CREATE_SKILL_AREA}
                        Component={Pages.HqEditIndividualSkillArea}
                        isPrivate
                        allowedRoles={[roles.HQ]}
                        layout="fullScreen"
                      />
                      <Route
                        exact
                        path={navRoutes.HQ.INDIVIDUAL_SKILL_AREA}
                        Component={Pages.IndividualSkillArea}
                        isPrivate
                        allowedRoles={[roles.HQ]}
                        layout="fullScreen"
                      />
                      <Route
                        exact
                        path={navRoutes.HQ.SUGGESTED_ACTIVITIES}
                        Component={Pages.HqSuggestedActivities}
                        isPrivate
                        allowedRoles={[roles.HQ]}
                        layout="fullScreen"
                      />
                      <Route
                        exact
                        path={navRoutes.HQ.REVIEW_SUGGESTED_ACTIVITIES_SUCCESS}
                        Component={Pages.ReviewSuggestSuccess}
                        isPrivate
                        allowedRoles={[roles.HQ]}
                        layout="fullScreen"
                      />
                      <Route
                        exact
                        path={navRoutes.HQ.REVIEW_SUGGESTED_ACTIVITIES}
                        Component={Pages.ReviewSuggestActivity}
                        isPrivate
                        allowedRoles={[roles.HQ]}
                        layout="fullScreen"
                      />
                      {/* VOLUNTEER ROUTES */}
                      <Route
                        exact
                        path={navRoutes.VOLUNTEER.CHECK_IN}
                        Component={Pages.CheckIn}
                        layout="fullScreen"
                        headerIcon="crown"
                      />
                      <Route
                        exact
                        path={navRoutes.VOLUNTEER.CHECK_IN_ASSESSMENT}
                        Component={Pages.CheckInAssessment}
                        layout="fullScreen"
                        headerIcon="crown"
                      />
                      <Route
                        exact
                        path={navRoutes.GENERAL.HELP_OTHERS}
                        Component={Pages.HelpOthers}
                        layout="fullScreen"
                      />
                      <Route
                        exact
                        path={navRoutes.VOLUNTEER.DASHBOARD}
                        Component={Pages.VolunteerDashboard}
                        layout="fullScreen"
                        showHelpButton
                        hideBack
                      />
                      <Route
                        exact
                        isPrivate
                        allowedRoles={[roles.VOLUNTEER, roles.DIGITAL_CHAMPION]}
                        path={navRoutes.VOLUNTEER.RECOMMENDED_SKILLS}
                        Component={Pages.RecommendedSkills}
                        layout="fullScreen"
                        showHelpButton
                      />
                      <Route
                        exact
                        path={navRoutes.GENERAL.REQUEST_DIGITAL_CHAMPION_SUCESS}
                        Component={Pages.RequestAChampionSuccess}
                        layout="fullScreen"
                      />
                      <Route
                        exact
                        path={navRoutes.GENERAL.REQUEST_DIGITAL_CHAMPION}
                        Component={Pages.RequestAChampion}
                        layout="fullScreen"
                        hideBack
                      />
                      <Route
                        exact
                        path={navRoutes.GENERAL.SKILL}
                        Component={Pages.VolunteerIndividualSkill}
                        layout="fullScreen"
                        showShare
                        showHelpButton
                      />
                      <Route
                        exact
                        path={navRoutes.GENERAL.SKILL_AREA}
                        Component={Pages.SkillArea}
                        layout="fullScreen"
                        showShare
                        showHelpButton
                      />
                      <Route
                        exact
                        path={navRoutes.GENERAL.ACTIVITY}
                        Component={Pages.VolunteerIndividualActivity}
                        layout="fullScreen"
                        withoutPaddings
                        showShare
                        showHelpButton
                      />
                      <Route
                        exact
                        path={navRoutes.GENERAL.SKILL_ACTIVITY}
                        Component={Pages.VolunteerIndividualActivity}
                        layout="fullScreen"
                        goBack
                        withoutPaddings
                        showShare
                        showHelpButton
                      />
                      <Route
                        exact
                        path={navRoutes.HQ.SUCCESS_EDIT_ACTIVITY}
                        Component={Pages.SuccessUpdateActivity}
                        layout="fullScreen"
                      />
                      <Route
                        exact
                        path={navRoutes.HQ.SUCCESS_ADD_ACTIVITY}
                        Component={Pages.SuccessUpdateActivity}
                        layout="fullScreen"
                        headerIcon="success"
                        goBack
                      />
                      <Route
                        exact
                        path={navRoutes.HQ.ADD_ACTIVITY}
                        Component={Pages.UpdateActivity}
                        isPrivate
                        allowedRoles={[roles.HQ]}
                        layout="fullScreen"
                      />
                      <Route
                        exact
                        path={navRoutes.HQ.EDIT_ACTIVITY}
                        Component={Pages.UpdateActivity}
                        isPrivate
                        allowedRoles={[roles.HQ]}
                        layout="fullScreen"
                      />
                      {/* General routes */}
                      <Route
                        exact
                        path={navRoutes.DIGITAL_CHAMPION.RESOURCE_SPACE}
                        Component={Pages.ResourceSpace}
                        layout="fullScreen"
                        hideBack
                      />
                      <Route
                        exact
                        path={navRoutes.GENERAL.SEARCH}
                        Component={Pages.Search}
                        layout="fullScreen"
                        withoutPaddings
                        showHelpButton
                      />
                      <Route
                        exact
                        path={navRoutes.GENERAL.DIGITAL_CHAMPIONS}
                        Component={Pages.DigitalChampions}
                        isPrivate
                        allowedRoles={[roles.DIGITAL_CHAMPION, roles.HQ]}
                        layout="fullScreen"
                        hideBack
                      />
                      <Route
                        exact
                        path={navRoutes.GENERAL.DIGITAL_CHAMPION_DETAILS}
                        Component={Pages.DigitalChampionDetails}
                        isPrivate
                        allowedRoles={[roles.DIGITAL_CHAMPION, roles.HQ]}
                        layout="fullScreen"
                      />
                      <Route
                        exact
                        path={navRoutes.GENERAL.LANDING}
                        Component={Pages.AboutDigitalChampion}
                        layout="fullScreen"
                        backBgColor="litestGray"
                      />
                      <Route
                        exact
                        path={navRoutes.GENERAL.MEET_CHAMPIONS}
                        Component={Pages.MeetOurDigitalChampion}
                        layout="fullScreen"
                        backBgColor="litestGray"
                      />
                      <Route
                        exact
                        path={navRoutes.GENERAL.HOME}
                        Component={Pages.VolunteerLanding}
                        layout="fullScreen"
                        hideBack
                        showHelpButton
                      />

                      <Route
                        exact
                        path={navRoutes.HQ.HOME}
                        Component={Pages.HQLanding}
                        layout="fullScreen"
                      />
                      <Route
                        exact
                        path={navRoutes.GENERAL.ASSESSMENT_STEP}
                        Component={Pages.Assessment}
                        layout="fullScreen"
                      />
                      <Route
                        exact
                        path={navRoutes.GENERAL.ASSESSMENT}
                        Component={Pages.TestLanding}
                        layout="fullScreen"
                      />
                      <Route
                        exact
                        path={navRoutes.GENERAL.ASSESSMENT_LANDING}
                        Component={Pages.AssessmentLanding}
                        layout="fullScreen"
                      />
                      <Route
                        exact
                        path={navRoutes.GENERAL.ACTIVITY_COMPLETED}
                        Component={Pages.ActivityCompleted}
                        layout="fullScreen"
                      />
                      {/* SETTINGS */}
                      {/* <Route
                    exact
                    path={navRoutes.GENERAL.SETTINGS}
                    Component={Pages.MyAccount}
                    layout="fullScreen"
                    isPrivate
                    allowedRoles={[roles.VOLUNTEER, roles.HQ]}
                  /> */}

                      <Route
                        exact
                        path={navRoutes.VOLUNTEER.PROGRESS}
                        Component={Pages.TrainingProgress}
                        layout="fullScreen"
                        hideBack
                      />
                      <Route
                        exact
                        path={navRoutes.VOLUNTEER.EXPERT_BADGE}
                        Component={Pages.ExpertBadge}
                        layout="fullScreen"
                        allowedRoles={[roles.DIGITAL_CHAMPION, roles.VOLUNTEER]}
                        hideBack
                      />
                      <Route
                        exact
                        path={'/test'}
                        Component={Pages.ExampleFullScreen}
                        layout="fullScreen"
                      />
                      <Route
                        exact
                        path={navRoutes.VOLUNTEER.BOOK_MARKED_SKILLS}
                        Component={Pages.BookmarkedSkillsPage}
                        layout="fullScreen"
                        isPrivate
                        allowedRoles={[roles.VOLUNTEER, roles.DIGITAL_CHAMPION]}
                      />
                      <Route
                        exact
                        path={'/500'}
                        Component={Pages.ErrorRoute}
                        layout="fullScreen"
                        goBack
                        status="500"
                        title="Something went wrong"
                        msg="Sorry, something went wrong with your request. Please try again or contact us for assistance."
                      />
                      <Route
                        Component={Pages.ErrorRoute}
                        path={navRoutes.GENERAL.UNAUTHORIZED}
                        layout="fullScreen"
                        goBack
                        status="403"
                        title="Unauthorised"
                        msg="Sorry, you don't have the permission to access this page"
                      />
                      {/* Uncomment if you want to implement upload feature in future */}
                      {/* <Route exact path={'/test-upload'} Component={UploadTest} /> */}
                      <Route
                        Component={Pages.ErrorRoute}
                        layout="fullScreen"
                        goBack
                        status="404"
                        title="Page not found"
                        msg="Sorry, the page you're looking for doesn't seem to exist"
                      />
                    </Switch>
                  </SSOProvider>
                </RRDRoute>
              </Switch>
            </AppStoreProvider>
          </AuthProvider>
        </Router>
      </ThemeProvider>
    </div>
  );
}

export default App;
