import { fields, createSchema, validate as _validate } from '..';

const { skillTitle, optionalFiled, skillTask, hideSkill } = fields;

const updateSkill = createSchema({
  title: skillTitle,
  description: optionalFiled,
  hideSkill,
  task1: skillTask,
  task2: skillTask,
  task3: skillTask,
});

const updateSkillHideSkill = createSchema({
  hideSkill,
});

const validate = (data) => {
  if (data.hideSkill) {
    return _validate(updateSkillHideSkill, data);
  }
  return _validate(updateSkill, data);
};

export default validate;
