import {
  useEffect,
  createContext,
  useContext,
  useReducer,
  useRef,
} from 'react';
import { Users } from '../api-calls';
import { LoginAndSignupPrompt } from '../components/SSOModals';
import { useHistory } from 'react-router-dom';
import { useAuth } from './auth';
import { useMsal } from '@azure/msal-react';
import { roles, navRoutes } from '../constants';
import { handleClickForAnalytics } from '../helpers';
import moment from 'moment';
import Loading from '../components/Loading';
import { loginRequest } from '../helpers/auth-config';

// Define user actions
const ACTIONS = {
  SET_LOADING: 'SET_LOADING',
  SET_ERROR: 'SET_ERROR',
  SET_LOGIN_AND_SIGNUP_PROMPT: 'SET_LOGIN_AND_SIGNUP_PROMPT',
};

// Initial state
const initialState = {
  loading: true,
  error: null,
  loginAndSignupPrompt: null, // Tracks login and signup prompt {type: 'login'/'signup', errorContent: 'error message'}
};

// Reducer function
const reducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.SET_LOADING:
      return { ...state, loading: action.payload };
    case ACTIONS.SET_ERROR:
      return { ...state, error: action.payload };
    case ACTIONS.SET_LOGIN_AND_SIGNUP_PROMPT:
      return { ...state, loginAndSignupPrompt: action.payload };
    default:
      return state;
  }
};

const getRedirectPath = (data) => {
  if (data.role === roles.VOLUNTEER) {
    const diff = moment().diff(moment(data.lastCheckIn), 'days');
    return diff >= 7
      ? navRoutes.VOLUNTEER.CHECK_IN
      : navRoutes.VOLUNTEER.DASHBOARD;
  } else if (data.role === roles.DIGITAL_CHAMPION) {
    return navRoutes.DIGITAL_CHAMPION.DASHBOARD;
  }
  return navRoutes.HQ.DASHBOARD;
};

const SSOContext = createContext({
  logout: () => {},
  volunteerSignup: () => {},
  digitalChampionSignup: () => {},
});

const SSOProvider = (props) => {
  const { instance, accounts } = useMsal();
  const [state, dispatch] = useReducer(reducer, initialState);
  const intent = useRef(null);

  const { setUser, user } = useAuth();
  const history = useHistory();

  const loginUserInApp = async (tokenResponse) => {
    const { idToken } = tokenResponse;
    const userState = intent.current;

    const { data, error } = await Users.login({ idToken });

    if (error) {
      handleClickForAnalytics({
        name: 'login_error',
        category: 'Login',
        action: `Login_errors_${data?.role}`,
      });

      intent.current = null;
    } else {
      setUser(data);

      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      handleClickForAnalytics({
        category: 'Login',
        action: `Login_success_${data.role}`,
      });

      if (!userState?.action) return; // auto login, no need to redirect
      const redirectPath = getRedirectPath(data);
      history.push(redirectPath);
    }
  };

  const promptForSignup = () => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    dispatch({
      type: ACTIONS.SET_LOGIN_AND_SIGNUP_PROMPT,
      payload: {
        type: 'login',
        errorContent:
          'We could not find your account on the Digital Skills Platform. Please sign up to continue.',
      },
    });
  };

  const promptForLogin = () => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    dispatch({
      type: ACTIONS.SET_LOGIN_AND_SIGNUP_PROMPT,
      payload: {
        type: 'signup',
        errorContent:
          'An account with this membership already exists. Please log in instead.',
      },
    });
  };
  const handleSignup = async (tokenResponse, signupRole) => {
    const { idToken } = tokenResponse;
    history.push({
      pathname:
        signupRole === roles.VOLUNTEER
          ? navRoutes.VOLUNTEER.SIGNUP
          : navRoutes.DIGITAL_CHAMPION.SIGNUP,
      state: { idToken },
    });
  };

  const handlePostCheckUserInDB = async (user, tokenResponse) => {
    // Retrieve state (intent and role) after redirect
    const userState = intent.current;

    if (userState?.action === 'login') {
      if (user) return await loginUserInApp(tokenResponse);
      return promptForSignup();
    } else if (userState?.action === 'signup') {
      if (user) return promptForLogin();
      return await handleSignup(tokenResponse, userState.role);
    } else {
      if (user) {
        await loginUserInApp(tokenResponse);
      }
    }
  };

  const handlePostLogin = async (tokenResponse) => {
    const { idTokenClaims } = tokenResponse;
    const { extension_MembershipNumber } = idTokenClaims;

    const { data: user } = await Users.getUserByMembershipNo({
      membershipNo: extension_MembershipNumber,
    });

    await handlePostCheckUserInDB(user, tokenResponse);
  };

  const acquireToken = async (account) => {
    try {
      dispatch({ type: ACTIONS.SET_LOADING, payload: true });
      const response = await instance.acquireTokenSilent({
        ...loginRequest(intent.current),
        account,
      });
      await handlePostLogin(response);
    } catch (error) {
      if (
        error.errorCode === 'interaction_required' ||
        error.errorCode === 'consent_required' ||
        error.errorCode === 'no_account_error'
      ) {
        await instance.loginRedirect({
          ...loginRequest(intent.current),
          account,
        });
      } else {
        dispatch({ type: ACTIONS.SET_ERROR, payload: error.message });
      }
    } finally {
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    }
  };

  useEffect(() => {
    let isMounted = true; // Track if component is still mounted
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });

    const attemptSilentLogin = async () => {
      const account = accounts[0];

      if (!user?.id && account) {
        console.log('attemptSilentLogin');
        try {
          const response = await instance.acquireTokenSilent({
            ...loginRequest(intent.current),
            account,
          });

          if (isMounted) {
            console.log('attemptSilentLogin response', response);
            await handlePostLogin(response); // Handle login logic
            dispatch({ type: ACTIONS.SET_LOADING, payload: false });
          }
        } catch (error) {
          if (isMounted) {
            console.log('Silent login failed', error);
          }
        }
      } else if (!user?.id) {
        return await instance.loginRedirect({
          ...loginRequest(intent.current),
          prompt: 'none', // if user is not logged in, do not prompt for login
        });
      }

      if (isMounted) {
        dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      }
    };

    instance
      .handleRedirectPromise()
      .then((response) => {
        const logoutInProgress = localStorage.getItem('logoutInProgress');
        if (logoutInProgress) {
          localStorage.removeItem('logoutInProgress');
          dispatch({ type: ACTIONS.SET_LOADING, payload: false });
          return;
        }

        if (response) {
          // Retrieve state (intent and role) after redirect
          const userState = response?.state
            ? JSON.parse(response.state)
            : intent.current;

          intent.current = userState;

          return acquireToken(response.account || accounts[0]);
        } else {
          intent.current = null;
          return attemptSilentLogin();
        }
      })
      .catch((error) => {
        dispatch({ type: ACTIONS.SET_LOADING, payload: false });
        dispatch({ type: ACTIONS.SET_ERROR, payload: error.message });
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instance, accounts]);

  const onClickLoginButton = () => {
    intent.current = { action: 'login' };
    acquireToken(accounts[0]);
  };

  const logout = async () => {
    try {
      const { error } = await Users.logout();
      if (!error) {
        history.push(navRoutes.GENERAL.HOME);
        dispatch({ type: ACTIONS.SET_LOADING, payload: false });
        intent.current = null;

        setUser({});
        localStorage.setItem('logoutInProgress', 'true');
        await instance.logoutRedirect({
          postLogoutRedirectUri: window.location.origin,
        });
        handleClickForAnalytics({
          name: 'logout',
          category: 'Logout',
          action: 'Logout (success)',
        });
      }
    } catch (err) {
      handleClickForAnalytics({
        name: 'logout',
        category: 'Logout',
        action: `Logout errors`,
      });
      console.error(err);
    }
  };
  const handleClickOnSignup = (role) => {
    intent.current = { action: 'signup', role };
    acquireToken(accounts[0]);
  };

  const onLoginAfterSignup = () => {
    dispatch({
      type: ACTIONS.SET_LOGIN_AND_SIGNUP_PROMPT,
      payload: null,
    });
    onClickLoginButton();
  };

  const value = {
    login: onClickLoginButton,
    logout,
    volunteerSignup: () => handleClickOnSignup(roles.VOLUNTEER),
    digitalChampionSignup: () => handleClickOnSignup(roles.DIGITAL_CHAMPION),
  };
  const activeAccount = instance.getActiveAccount();
  console.log('accounts', accounts);
  console.log('activeAccount', activeAccount);

  return (
    <SSOContext.Provider value={value} {...props}>
      {state.loading || !user?.fetched ? (
        <Loading pageLoading />
      ) : (
        <>
          <LoginAndSignupPrompt
            errorModalType={state?.loginAndSignupPrompt?.type}
            setIsErrorModalOpen={() =>
              dispatch({
                type: ACTIONS.SET_LOGIN_AND_SIGNUP_PROMPT,
                payload: null,
              })
            }
            handleLogin={onLoginAfterSignup}
            errorModalContent={state?.loginAndSignupPrompt?.errorContent}
            account={instance.getActiveAccount()}
          />

          {props.children}
        </>
      )}
    </SSOContext.Provider>
  );
};

const useSSO = () => useContext(SSOContext);

export { SSOProvider, useSSO };
export default SSOContext;
